<template>
  <h1 class="text-2xl font-semibold text-gray-900">Werkdagen</h1>
  <div class="py-4">
    <UISelectChauffeur label active v-model="user_id" />

    <UITableResponsive
      v-if="user_id"
      class="mt-4"
      :items="[werkdagen]"
      :headers="dagen"
      :prefix-headers="['Selectie']"
      :suffix-headers="['Acties']"
    >
      <template v-slot:colgroup>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-auto" />
        </colgroup>
      </template>

      <template v-for="dag in dagen" v-slot:[`item-${dag}`]="{ item }">
        <div class="flex flex-col gap-2 card items-center justify-center px-4" :key="dag">
          <span v-for="dagDeel in ['VM', 'NM']" :key="`${dag}_${dagDeel}`">
            <UIInputCheckbox v-model="item[`${dag}_${dagDeel}`]" :label="dagDeel" />
          </span>
        </div>
      </template>

      <template v-slot:item-Selectie="{}">
        <button class="btn purple" @click="() => selecteerAlles()">Alles selecteren</button>
      </template>
      <template v-slot:item-Acties="{ item }">
        <button class="btn success" @click="() => save(item)">Opslaan</button>
      </template>
    </UITableResponsive>
  </div>
</template>

<script setup>
import { onMounted, ref, inject, watch } from 'vue'
import UISelectChauffeur from '@/components/UI/Select/Chauffeur.vue'
import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import UIInputCheckbox from '@/components/UI/Input/Checkbox'

const dagen = ['MA', 'DI', 'WO', 'DO', 'VR', 'ZA', 'ZO']

const axios = inject('axios')
// const modal = inject('modal')
let page = ref(1)
let user_id = ref(null)
let werkdagen = ref({})

const refreshData = async () => {
  const params = { user_id: user_id.value, page: page.value }
  werkdagen.value = []
  if (!params.user_id) return
  const { data } = await axios.get('/api/dashboard/werkdagen/list', { params })
  werkdagen.value = data.werkdagen || { user_id: user_id.value }
}

const selecteerAlles = () => {
  dagen.forEach(dag => {
    werkdagen.value[`${dag}_VM`] = true
    werkdagen.value[`${dag}_NM`] = true
  })
}

onMounted(() => refreshData())
watch(user_id, (o, v) => {
  if (o !== v) refreshData()
})
const save = async item => {
  await axios.patch('/api/dashboard/werkdagen/save', { user_id: item.id, ...item })
}
</script>
